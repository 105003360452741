export const LARAVEL_MINIMAL_PROD_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-laravel-minimal-prod
  tags:
    - zerops-recipe
    - production

services:
  - hostname: app
    type: php-nginx@8.3
    buildFromGit: https://github.com/zeropsio/recipe-laravel-minimal
    enableSubdomainAccess: true
    envSecrets:
      APP_KEY: <@generateRandomString(<32>)>
      APP_DEBUG: true
      APP_ENV: development

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10`;
