export const LARAVEL_JETSTREAM_PROD_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-laravel-jetstream-prod
  tags:
    - zerops-recipe
    - production

services:
  - hostname: app
    type: php-nginx@8.4
    buildFromGit: https://github.com/zeropsio/recipe-laravel-jetstream
    enableSubdomainAccess: true
    envSecrets:
      APP_NAME: ZeropsLaravelJetstreamProd
      APP_DEBUG: false
      APP_ENV: production
      APP_KEY: <@generateRandomString(<32>)>

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10

  - hostname: redis
    type: keydb@6
    mode: NON_HA
    priority: 10

  - hostname: storage
    type: object-storage
    objectStorageSize: 2
    objectStoragePolicy: public-read
    priority: 10`;
