export const LARAVEL_MINIMAL_DEVEL_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-laravel-minimal-devel
  tags:
    - zerops-recipe
    - development

services:
  - hostname: app
    type: php-nginx@8.3
    buildFromGit: https://github.com/zeropsio/recipe-laravel-minimal
    enableSubdomainAccess: true
    envSecrets:
      APP_KEY: <@generateRandomString(<32>)>
      APP_DEBUG: true
      APP_ENV: development

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10

  - hostname: adminer
    type: php-apache@8.3
    buildFromGit: https://github.com/zeropsio/recipe-adminer@main
    enableSubdomainAccess: true`;
