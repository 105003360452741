import { Component, Input, inject } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { Clipboard } from '@angular/cdk/clipboard';
import { ZefSnackService } from '@zerops/zef/snack';
import { ThemeService } from '@zerops/zef/theme';
import { GeneralTranslations } from '@zerops/zerops/app';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { PipelineDetailModes } from '@zerops/zerops/feature/pipeline-detail';
import { ProcessEntity } from '@zerops/zerops/core/process-base';
import { ProcessActions, ProcessStatuses } from '@zerops/models/process';
import { ServiceStackTypeCategories, ServiceStackTypes } from '@zerops/models/service-stack';
import {
  golangZeropsYml,
  staticZeropsYml,
  nginxStaticZeropsYml,
  nodejsZeropsYml,
  phpApacheZeropsYml,
  phpNginxZeropsYml,
  ubuntuZeropsYml,
  dotnetZeropsYml,
  createPhpBuildBaseFromVersionType,
  rustZeropsYml,
  pythonZeropsYml,
  bunZeropsYml,
  denoZeropsYml,
  javaZeropsYml,
  alpineZeropsYml,
  elixirZeropsYml,
  gleamZeropsYml,
  dockerZeropsYml
} from './ready-to-deploy.utils';
import { ProcessItemTranslations, PROCESS_ITEM_FEATURE_NAME, ProcessItemModes } from '@zerops/zui/process-item';
import { environment } from '@zerops/zerops/env';
import { FEATURE_NAME } from './ready-to-deploy.constant';

@Component({
  selector: 'z-ready-to-deploy',
  templateUrl: './ready-to-deploy.feature.html',
  styleUrls: [ './ready-to-deploy.feature.scss' ]
})
export class ReadyToDeployFeature extends ZefReactiveComponent {
  /**
  Dependency injections
   */
  #themeService = inject(ThemeService);

  // # Data
  // -- sync
  defaultMode: PipelineDetailModes = 'PIPELINE';
  serviceStackTypeCategories = ServiceStackTypeCategories;
  featureName = FEATURE_NAME;
  processStatuses = ProcessStatuses;
  serviceStackTypes = ServiceStackTypes;
  userProjectZeropsYaml: string;
  region = environment.region;
  processItemModes = ProcessItemModes;

  // - angular
  @ObservableInput()
  @Input('serviceStackId')
  serviceStackId$!: Observable<string>;

  // -- async
  serviceStack$ = this.serviceStackId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  projectId$ = this.serviceStack$.pipe(
    filter((d) => !!d),
    map((d) => d?.projectId)
  );
  projectProcesses$ = this.projectId$.pipe(switchMap((id) => this._processEntity
    .list$()
    .pipe(map((d) => (d || []).filter((itm) => itm.projectId === id)))
  ));
  serviceProcesses$ = this.projectProcesses$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ d, id ]) => (d || []).filter((itm) => itm.serviceStackId === id))
  );
  projectCoreProcess$ = this.projectProcesses$.pipe(
    map((d) => d.find((itm) => itm.actionName === ProcessActions.instanceCreate)),
    distinctUntilChanged()
  );
  buildProcess$ = this.serviceProcesses$.pipe(
    map((d) => d.find((itm) => itm.actionName === ProcessActions.stackBuild)),
    distinctUntilChanged()
  );
  deployProcess$ = this.serviceProcesses$.pipe(
    map((d) => d.find((itm) => itm.actionName === ProcessActions.stackDeploy)),
    distinctUntilChanged()
  );
  projectZeropsYaml$ = this.serviceStack$.pipe(
    map((d) => this._zeropsYmlByServiceType(
      d.name,
      d.serviceStackTypeId,
      d.serviceStackTypeId === ServiceStackTypes.Static
        ? 'nodejs@18'
        : d.serviceStackTypeInfo?.serviceStackTypeVersionName
    )),
    distinctUntilChanged(),
    // prefill monaco value with default value
    tap((s) => this.userProjectZeropsYaml = s)
  )
  processItemTranslations$ = this.translate$<ProcessItemTranslations>(PROCESS_ITEM_FEATURE_NAME);
  generalTranslations$ = this.translate$<GeneralTranslations>('general');

  // # State resolver
  state = this.$connect({
    serviceStack: this.serviceStack$,
    buildProcess: this.buildProcess$,
    deployProcess: this.deployProcess$,
    projectCoreProcess: this.projectCoreProcess$,
    generalTranslations: this.generalTranslations$,
    projectZeropsYaml: this.projectZeropsYaml$,
    processItemTranslations: this.processItemTranslations$,
    isCompact: this.#themeService.isCompact$
  });

  constructor(
    private _processEntity: ProcessEntity,
    private _serviceStackEntity: ServiceStackEntity,
    private _clipboardService: Clipboard,
    private _snack: ZefSnackService
  ) {
    super();
  }

  copyZeropsYamlToClipboard() {
    this._clipboardService.copy(this.userProjectZeropsYaml);
    this._snack.info$({ translation: 'general.copySuccess' }, { duration: 800 });
  }

  private _zeropsYmlByServiceType(serviceName: string, serviceType: string, serviceTypeVersion: string) {

    if (!serviceName || !serviceType || !serviceTypeVersion) {
      return undefined;
    }

    if (serviceType === ServiceStackTypes.NodeJs) {
      return nodejsZeropsYml(serviceName, serviceTypeVersion)
    }

    if (serviceType === ServiceStackTypes.Bun) {
      return bunZeropsYml(serviceName, serviceTypeVersion)
    }

    if (serviceType === ServiceStackTypes.Deno) {
      return denoZeropsYml(serviceName, serviceTypeVersion)
    }

    if (serviceType === ServiceStackTypes.Java) {
      return javaZeropsYml(serviceName, serviceTypeVersion)
    }

    if (serviceType === ServiceStackTypes.Ubuntu) {
      return ubuntuZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Alpine) {
      return alpineZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Docker) {
      return dockerZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Elixir) {
      return elixirZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Gleam) {
      return gleamZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.PhpApache) {
      return phpApacheZeropsYml(serviceName, serviceTypeVersion, createPhpBuildBaseFromVersionType(serviceTypeVersion))
    }

    if (serviceType === ServiceStackTypes.PhpNginx) {
      return phpNginxZeropsYml(serviceName, serviceTypeVersion, createPhpBuildBaseFromVersionType(serviceTypeVersion))
    }

    if (serviceType === ServiceStackTypes.Golang) {
      return golangZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Static) {
      return staticZeropsYml(serviceName);
    }

    if (serviceType === ServiceStackTypes.Nginx) {
      return nginxStaticZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.DotNet) {
      return dotnetZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Rust) {
      return rustZeropsYml(serviceName, serviceTypeVersion);
    }

    if (serviceType === ServiceStackTypes.Python) {
      return pythonZeropsYml(serviceName, serviceTypeVersion);
    }

  }

}
