import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  isZeropsContabo: '$$ZEROPS_DATA_IS_ZEROPS_CONTABO$$',
  currentProductionEnvName: 'app_production',
  intercomAppId: '$$ZEROPS_DATA_INTERCOM_APP_ID$$',
  enableIntercom: '$$ZEROPS_DATA_ENABLE_INTERCOM$$',
  documentationUrl: '$$ZEROPS_DATA_DOCS_URL$$',
  projectInfoUrl: 'https://info.zerops.io/',
  forceSecuredWebsocket: true,
  versionInfoPath: '/assets/version.json',
  rollbarAccessToken: 'd270c426ea76465781ad6bb512eb7ef0',
  envName: 'alpha',
  appVersion: '',
  githubAuthCallbackUrl: 'https://app.zerops.dev/github-auth',
  gitlabAuthCallbackUrl: 'https://app.zerops.dev/gitlab-auth',
  apiUrl: 'https://api.app-alpha.zerops.dev/api/rest/public',
  webApiUrl: 'https://web-api.zerops.io',
  websocketHost: 'api.app-alpha.zerops.dev',
  websocketLoginUrl: 'https://api.app-alpha.zerops.dev/api/rest/public/web-socket/login',
  googleSiteKey: '6LdUw48UAAAAALnxsH_vUxMNlAVWrCvd-OS-g2nC',
  stripePublishableKey: 'pk_test_51OnGrVLOIvSR867sY6CwqGkz2JYaPzNmT6cjGmxxpYagHUlW09kiSU6exv24VoTXPIQp3klDj6y572VmCkHeXUdA00P7sotusw',
  sharedIPv4: '$$ZEROPS_DATA_SHARED_IPV4$$',
  region: 'zerops',
  hideVersionWarning: '$$ZEROPS_HIDE_VERSION_WARNING$$',
  enableRemoteShell: '$$ZEROPS_ENABLE_REMOTE_SHELL$$',
};
